// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-hamburger-nav-js": () => import("./../../../src/pages/hamburgerNav.js" /* webpackChunkName: "component---src-pages-hamburger-nav-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nav-js": () => import("./../../../src/pages/nav.js" /* webpackChunkName: "component---src-pages-nav-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

